<template>
  <div class="anti-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="anti-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="anti-context-menu__item standart-title"
          :class="{ [`anti-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="clouds-context-menu__noact standart-text">
        <div>{{ $t('actions.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { AntiDdosTariff } from '@/models/BillMgr/AntiDdosTariff';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'MoContextMenu',
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof AntiDdosTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tools() {
      return this.$store.state.moduleAntiDdos.tools;
    },
    menu() {
      return [
        [
          // {
          //   key: 'serverPanel',
          //   disabled: this.tariff.status.code === 1,
          //   to: '',
          // },
        ],
      ];
    },
  },
  methods: {
    onClick(item) {
      window.open(item.to);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "serverPanel":"портал",
      "noactions": "Нет доступных действий."
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.anti-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.anti-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
